






























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class MaterialCard extends Vue {
  @Prop({ default: 'primary' }) color!: string;

  @Prop({ default: 2 }) elevation!: number;

  @Prop({ default: '', type: String }) title!: string;

  @Prop({ default: '', type: String }) addButtonTooltip!: string;

  @Prop({ default: 'accent', type: String }) addButtonColor!: string;

  @Prop({ default: 'add-button', type: String }) addButtonId!: string;

  @Prop({ default: false, type: Boolean }) disableAddButton!: boolean;

  hasSlot(name: string): boolean {
    return name in this.$slots;
  }

  hasHandler(name: string): boolean {
    return name in this.$listeners;
  }

  hasExtension() {
    return this.hasHandler('click:add') || this.hasSlot('extension');
  }
}
